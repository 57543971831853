import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component'
import { RosettiTowerComponent } from './rosetti-tower/rosetti-tower.component';
import { ArmandCenterComponent } from './armand-center/armand-center.component';
import { CoworkingSpaceComponent } from './coworking-space/coworking-space.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { NewsComponent } from './news/news.component';
import { ContactComponent } from './contact/contact.component';
import { RosettiTowerInstantOfficesComponent } from './rosetti-tower-instant-offices/rosetti-tower-instant-offices.component';
import { RosettiTowerMeetingRoomsComponent } from './rosetti-tower-meeting-rooms/rosetti-tower-meeting-rooms.component';
import { RosettiTowerVirtualOfficesComponent } from './rosetti-tower-virtual-offices/rosetti-tower-virtual-offices.component';
import { ArmandCenterInstantOfficesComponent } from './armand-center-instant-offices/armand-center-instant-offices.component';
import { ArmandCenterMeetingRoomsComponent } from './armand-center-meeting-rooms/armand-center-meeting-rooms.component';
import { ArmandCenterVirtualOfficesComponent } from './armand-center-virtual-offices/armand-center-virtual-offices.component';
import { BenefitsProfessionalServicesComponent } from './benefits-professional-services/benefits-professional-services.component';
import { BenefitsFlashOfficeCardComponent } from './benefits-flash-office-card/benefits-flash-office-card.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [

// EN
// top level

{ path: '', component: HomeComponent },

{ path: 'properties/rosetti-tower', component: RosettiTowerComponent },
{ path: 'properties/armand-business-center', component: ArmandCenterComponent },
{ path: 'properties/rosetti-coworking-space', component: CoworkingSpaceComponent},
{ path: 'benefits', component: BenefitsComponent },
{ path: 'news', component: NewsComponent },
{ path: 'contact', component: ContactComponent },

// subs
{ path: 'about-us', component: AboutUsComponent },

{ path: 'properties/rosetti-tower/instant-offices', component: RosettiTowerInstantOfficesComponent },
{ path: 'properties/rosetti-tower/meeting-rooms', component: RosettiTowerMeetingRoomsComponent },
{ path: 'properties/rosetti-tower/virtual-offices', component: RosettiTowerVirtualOfficesComponent },

{ path: 'properties/armand-business-center/instant-offices', component: ArmandCenterInstantOfficesComponent },
{ path: 'properties/armand-business-center/meeting-rooms', component: ArmandCenterMeetingRoomsComponent },
{ path: 'properties/armand-business-center/virtual-offices', component: ArmandCenterVirtualOfficesComponent },

{ path: 'benefits/professional-services', component: BenefitsProfessionalServicesComponent },
{ path: 'benefits/flash-office-card', component: BenefitsFlashOfficeCardComponent },

// ,{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
