import { Component, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public currrentLang: any;


  constructor(private translate: TranslateService) {

    let currentDomain = document.location.hostname;

    if (localStorage.getItem('i18n')) {
          translate.setDefaultLang(localStorage.getItem('i18n'));
          translate.use(localStorage.getItem('i18n'));
      } else {

      switch (currentDomain) {
        case 'www.flashoffices.com':
          translate.setDefaultLang('en');
          translate.use('en');
          this.currrentLang = 'en';
          localStorage.setItem('i18n', 'en');
          break;
        case 'www.flashoffice.ro':
          translate.setDefaultLang('ro');
          translate.use('ro');
          this.currrentLang = 'ro';
          localStorage.setItem('i18n', 'ro');
          break;
        default:
          translate.setDefaultLang('en');
          translate.use('en');
          this.currrentLang = 'en';
          localStorage.setItem('i18n', 'en');
          break;
      }

    }
      
  }

  changeLang(lang) {
    console.log('Change lang: ', lang);
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    localStorage.setItem('i18n', lang);
  }

  gdprAccepted(){
    if (localStorage.getItem('gdpr') && localStorage.getItem('gdpr') == 'true') {
      return true;
    } else {
      return false;
    }
  }

  acceptGdpr() {
    localStorage.setItem('gdpr', 'true');
  }

}
