import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { RosettiTowerComponent } from './rosetti-tower/rosetti-tower.component';
import { RosettiTowerInstantOfficesComponent } from './rosetti-tower-instant-offices/rosetti-tower-instant-offices.component';
import { RosettiTowerMeetingRoomsComponent } from './rosetti-tower-meeting-rooms/rosetti-tower-meeting-rooms.component';
import { RosettiTowerVirtualOfficesComponent } from './rosetti-tower-virtual-offices/rosetti-tower-virtual-offices.component';
import { ArmandCenterComponent } from './armand-center/armand-center.component';
import { ArmandCenterInstantOfficesComponent } from './armand-center-instant-offices/armand-center-instant-offices.component';
import { ArmandCenterMeetingRoomsComponent } from './armand-center-meeting-rooms/armand-center-meeting-rooms.component';
import { ArmandCenterVirtualOfficesComponent } from './armand-center-virtual-offices/armand-center-virtual-offices.component';
import { CoworkingSpaceComponent } from './coworking-space/coworking-space.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { BenefitsProfessionalServicesComponent } from './benefits-professional-services/benefits-professional-services.component';
import { BenefitsFlashOfficeCardComponent } from './benefits-flash-office-card/benefits-flash-office-card.component';
import { NewsComponent } from './news/news.component';
import { ContactComponent } from './contact/contact.component';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HomeComponent } from './home/home.component';
import { SidebarComponent } from './sidebar/sidebar.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    AboutUsComponent,
    RosettiTowerComponent,
    RosettiTowerInstantOfficesComponent,
    RosettiTowerMeetingRoomsComponent,
    RosettiTowerVirtualOfficesComponent,
    ArmandCenterComponent,
    ArmandCenterInstantOfficesComponent,
    ArmandCenterMeetingRoomsComponent,
    ArmandCenterVirtualOfficesComponent,
    CoworkingSpaceComponent,
    BenefitsComponent,
    BenefitsProfessionalServicesComponent,
    BenefitsFlashOfficeCardComponent,
    NewsComponent,
    ContactComponent,
    HomeComponent,
    SidebarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
