import { Component, AfterViewInit, ViewChild, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-armand-center-instant-offices',
  templateUrl: './armand-center-instant-offices.component.html',
  styleUrls: ['./armand-center-instant-offices.component.scss']
})
export class ArmandCenterInstantOfficesComponent implements OnInit {

  @ViewChild('mapContainer', {static: false}) gmap: ElementRef;
  
  map: google.maps.Map;
  lat = 44.439409;
  lng = 26.109285;
  coordinates = new google.maps.LatLng(this.lat, this.lng);

  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 15,
  };

  marker = new google.maps.Marker({
    position: this.coordinates,
    map: this.map,
  });

  constructor() { 

  }

  mapInitializer() {
    this.map = new google.maps.Map(this.gmap.nativeElement, 
    this.mapOptions);

    this.marker.setMap(this.map);

   }

   ngAfterViewInit() {
    this.mapInitializer();
   }


  ngOnInit() {
  }

}
