import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-benefits-professional-services',
  templateUrl: './benefits-professional-services.component.html',
  styleUrls: ['./benefits-professional-services.component.scss']
})
export class BenefitsProfessionalServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
